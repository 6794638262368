/**
 * @flow
 */

import * as React from 'react';

import type {Style} from 'src/styles/themes';
import {IS_BROWSER, NOOP} from 'src/constants';

type ItemRendererProps<TItem> = $ReadOnly<{
  item: TItem,
}>;
type Props<TItem> = $ReadOnly<{
  itemsPerPage: number,
  allItems: $ReadOnlyArray<TItem>,
  ItemRenderer: React$ComponentType<ItemRendererProps<TItem>>,
  getItemKey: (TItem) => string,
  rootStyle?: Style,
  listStyle?: Style,
  listItemStyle?: Style,
  loadingIndicatorStyle?: Style,
  loadMoreButtonStyle?: Style,
}>;

/**
 * Implementation loosely based on here:
 *   - https://dev.to/hunterjsbit/react-infinite-scroll-in-few-lines-588f
 *   - https://medium.com/suyeonme/react-how-to-implement-an-infinite-scroll-749003e9896a
 *   - https://www.npmjs.com/package/react-infinite-scroll-component
 */
export default function InfiniteScroll<TItem>(props: Props<TItem>): React.Node {
  const {
    allItems,
    itemsPerPage,
    ItemRenderer,
    getItemKey,
    rootStyle = {},
    listStyle = {},
    listItemStyle = {},
    loadingIndicatorStyle = {},
    loadMoreButtonStyle = {},
  } = props;
  // See here for how to check for IntersectionObserverSupport
  // https://github.com/w3c/IntersectionObserver/issues/296#issuecomment-452230176
  const observerIsNotSupported =
    !IS_BROWSER ||
    !('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype);
  const [page, setPage] = React.useState(
    IS_BROWSER ? window.history.state?.southerfieldsInfiniteScrollPage ?? 1 : 1,
  );
  const incrementPage = React.useMemo(
    () => () => {
      const nextPage = page + 1;
      if (IS_BROWSER) {
        window.history.replaceState(
          {southerfieldsInfiniteScrollPage: nextPage},
          '',
        );
      }
      setPage(nextPage);
    },
    [page, setPage],
  );
  const handleObserver = React.useMemo(
    () =>
      observerIsNotSupported
        ? NOOP
        : (entities) => {
            const target = entities[0];
            if (target.isIntersecting) {
              incrementPage();
            }
          },
    [observerIsNotSupported, incrementPage],
  );
  const observer = React.useMemo(
    () =>
      observerIsNotSupported
        ? null
        : new IntersectionObserver(handleObserver, {
            root: null,
            rootMargin: '20px',
          }),
    [observerIsNotSupported, handleObserver],
  );
  const prevLoadingIndicatorRef = React.useRef(null);
  const shownItems = React.useMemo(
    () => allItems.slice(0, page * itemsPerPage),
    [allItems, page, itemsPerPage],
  );
  const someItemNotShown = page * itemsPerPage < allItems.length;
  let loaderMarkup = null;
  if (someItemNotShown) {
    if (observerIsNotSupported) {
      loaderMarkup = (
        <button css={loadMoreButtonStyle} onClick={incrementPage}>
          Load More
        </button>
      );
    } else {
      const handleLoadingIndicatorRef = (ref) => {
        if (ref == null) {
          const prevRef = prevLoadingIndicatorRef.current;
          if (prevRef != null) {
            observer?.unobserve(prevRef);
          }
          return;
        }
        observer?.observe(ref);
        prevLoadingIndicatorRef.current = ref;
      };
      loaderMarkup = (
        <span css={loadingIndicatorStyle} ref={handleLoadingIndicatorRef}>
          Loading...
        </span>
      );
    }
  }
  return (
    <div css={rootStyle}>
      <ul css={listStyle}>
        {shownItems.map((item) => (
          <li key={getItemKey(item)} css={listItemStyle}>
            <ItemRenderer item={item} />
          </li>
        ))}
      </ul>
      {loaderMarkup}
    </div>
  );
}
