/**
 * @flow
 */

export function areSetsEqual<TItem>(
  set1: $ReadOnlySet<TItem>,
  set2: $ReadOnlySet<TItem>,
): boolean {
  if (set1.size !== set2.size) {
    return false;
  }
  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }
  return true;
}

export function extendSet<TItem>(
  set: $ReadOnlySet<TItem>,
  ...items: $ReadOnlyArray<TItem>
): $ReadOnlySet<TItem> {
  const result = new Set(set);
  for (const item of items) {
    result.add(item);
  }
  return result;
}

export function dextendSet<TItem>(
  set: $ReadOnlySet<TItem>,
  ...items: $ReadOnlyArray<TItem>
): $ReadOnlySet<TItem> {
  const result = new Set(set);
  for (const item of items) {
    result.delete(item);
  }
  return result;
}
