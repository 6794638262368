/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{
  label: string,
  value: boolean,
  onChange: (boolean) => any,
  id: string,
}>;

const styles = {
  label: {
    paddingLeft: 8,
    cursor: 'pointer',
  },
};

export default function Toggle({
  label,
  value,
  onChange,
  id,
}: Props): React.Node {
  return (
    <React.Fragment>
      <input
        id={id}
        type='checkbox'
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
      />
      <label css={styles.label} htmlFor={id}>
        {label}
      </label>
    </React.Fragment>
  );
}
