/**
 * @flow
 */

import * as React from 'react';
import {ChevronRightIcon} from 'src/components/icons/Icons';
import {linkStyle} from 'src/styles/common';

type Props = $ReadOnly<{
  id: string,
  title: string,
  initiallyIsExpanded: boolean,
  children: React.Node,
}>;

const chevronStyleBase = {
  display: 'inline-block',
  transition: 'transform 0.2s',
  marginRight: 8,
};
const styles = {
  button: (theme) => ({
    ...linkStyle(theme),
    marginBottom: 12,
    border: 0,
    background: 'unset',
    fontSize: `${theme.fontSizeMedium}rem`,
  }),
  regionExpanded: {},
  regionCollapsed: {
    display: 'none',
  },
  chevronExpanded: {
    ...chevronStyleBase,
    transform: 'rotate(90deg)',
  },
  chevronCollapsed: {
    ...chevronStyleBase,
  },
};

export default function Accordion(props: Props): React.Node {
  const {id, title, initiallyIsExpanded, children} = props;
  const [isExpanded, setIsExpanded] = React.useState(initiallyIsExpanded);
  const buttonId = id + 'button';
  const regionId = id + 'region';
  const regionStyle = isExpanded
    ? styles.regionExpanded
    : styles.regionCollapsed;
  const chevronStyle = isExpanded
    ? styles.chevronExpanded
    : styles.chevronCollapsed;
  return (
    <div>
      <h2>
        <button
          css={styles.button}
          aria-expanded={isExpanded}
          aria-controls={regionId}
          id={buttonId}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span css={chevronStyle}>
            <ChevronRightIcon />
          </span>
          {title}
        </button>
      </h2>
      <div
        css={regionStyle}
        id={regionId}
        role='region'
        aria-labelledby={buttonId}
      >
        {children}
      </div>
    </div>
  );
}
