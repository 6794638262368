/**
 * @flow
 */

import * as React from 'react';
import Toggle from 'src/components/input/Toggle';
import {extendSet, dextendSet} from 'src/utils/sets';

type Props<TKey> = $ReadOnly<{
  allKeys: $ReadOnlySet<TKey>,
  selectedKeys: $ReadOnlySet<TKey>,
  prettyPrintLabel?: ?(TKey) => string,
  onChange: (selectedKeys: $ReadOnlySet<TKey>) => any,
  id: string,
  title: string,
}>;

const styles = {
  root: {
    minWidth: 250,
  },
  list: (theme) => ({
    listStyleType: 'none',
    fontSize: `${theme.fontSizeSmall}rem`,
    marginLeft: 12,
    marginTop: 8,
  }),
  listItem: {
    margin: '4px 0',
  },
};

export default function ToggleGroup<TKey: string | number>(
  props: Props<TKey>,
): React.Node {
  const {title, id, allKeys, selectedKeys, onChange, prettyPrintLabel} = props;
  return (
    <div css={styles.root}>
      {title}
      <ul css={styles.list}>
        {Array.from(allKeys).map((key) => {
          const stringKey = key.toString();
          return (
            <li key={stringKey} css={styles.listItem}>
              <Toggle
                label={
                  prettyPrintLabel == null ? stringKey : prettyPrintLabel(key)
                }
                value={selectedKeys.has(key)}
                onChange={(nextVal) => {
                  const operation = nextVal ? extendSet : dextendSet;
                  const newSelectedKeys = operation(selectedKeys, key);
                  onChange(newSelectedKeys);
                }}
                id={id + stringKey}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
