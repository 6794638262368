/**
 * @flow
 */

import * as React from 'react';
import InternalLink from 'src/components/navigation/InternalLink';
import Page from 'src/components/Page';
import ToggleGroup from 'src/components/input/ToggleGroup';
import InfiniteScroll from 'src/components/InfiniteScroll';
import Accordion from 'src/components/Accordion';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import {getWithBreakpoints} from 'src/styles/breakpoints';
import {
  ensureProperImageryAlt,
  prettyPrintMediumGroup,
} from 'src/dataUtils/imagerySchemaUtils';
import {ELEMENT_IDS, IMAGERY_OG_IMAGE} from 'src/constants';
import {linkStyle, visuallyHiddenStyle} from 'src/styles/common';
import useImagerySearch from 'src/hooks/useImagerySearch';

import type {
  MediumGroup,
  ImageryWithImageData,
} from 'src/dataUtils/imagerySchemaUtils';

/**
 * IMPORTANT: breakpoint chosen to match theme.maxMainContentWidth
 */
const withBreakpoint = getWithBreakpoints([800]);
const resultsMessageBaseStyle = (theme) => ({
  marginTop: `${theme.pagePadding - theme.thumbnailPadding}rem`,
  marginBottom: [`${theme.pagePadding}rem`, 0],
  fontSize: `${theme.fontSizeMedium}rem`,
});
const styles = {
  pageRoot: (theme) =>
    withBreakpoint({
      flexGrow: 1,
      padding: [0, `${theme.pagePadding}rem`],
    }),
  root: {width: '100%'},
  searchOptions: (theme) => ({
    background: theme.unshadeColor,
    padding: `${theme.pagePadding}rem`,
  }),
  toggleGroupsList: (theme) => ({
    marginLeft: `${theme.pagePadding}rem`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    listStyleType: 'none',
  }),
  toggleGroupsListItem: (theme) => ({
    marginBottom: `${theme.pagePadding / 2}rem`,
  }),
  belowFilters: (theme) => ({
    fontSize: `${theme.fontSizeSmall}rem`,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }),
  matchCount: (theme) => ({
    color: theme.shadeColor,
    marginRight: '1.2rem',
  }),
  clearFiltersButton: (theme) => ({
    ...linkStyle(theme),
    border: 0,
    background: 0,
  }),
  resultsList: (theme) =>
    withBreakpoint({
      margin: `${theme.thumbnailPadding}rem`,
      paddingTop: [0, `${theme.pagePadding}rem`],
      listStyleType: 'none',
      display: 'grid',
      /**
       * IMPORTANT: If you change this, you probably also need to adjust the size
       * of the image fetched by the graphql query in order to avoid fetching
       * more/less image size than needed by the eventual layout.
       */
      gridTemplateColumns: '33.333% 33.333% 33.333%',
    }),
  noResults: (theme) =>
    withBreakpoint({
      ...resultsMessageBaseStyle(theme),
      paddingTop: `${theme.thumbnailPadding}rem`,
      textAlign: 'center',
    }),
  resultsImage: (theme) => ({
    ...linkStyle(theme),
    display: 'block',
    boxSizing: 'content-box',
    padding: `${theme.thumbnailPadding}rem`,
    ':hover': {
      background: theme.linkHoverColor,
    },
  }),
  imageryTitle: {
    paddingTop: 8,
    paddingLeft: 8,
  },
  infiniteScrollRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingIndicator: (theme) => withBreakpoint(resultsMessageBaseStyle(theme)),
  loadMoreButton: (theme) =>
    withBreakpoint({
      ...linkStyle(theme),
      ...resultsMessageBaseStyle(theme),
      border: 0,
      background: 'unset',
    }),
};

type ImagerySearchResultProps = $ReadOnly<{item: ImageryWithImageData}>;
function ImagerySearchResult(props: ImagerySearchResultProps): React.Node {
  const imagery = props.item;
  const image = imagery.images[0];
  // TODO: hovering over these causes frustrating page rerender. but maybe thats just a gatsby develop quirk?
  // When working on this page, switch them to <a>
  return image == null ? null : (
    <InternalLink
      to={imagery.gatsbyPath}
      preserveQueryParams={true}
      css={styles.resultsImage}
    >
      <h2 css={visuallyHiddenStyle}>{imagery.title}</h2>
      <GatsbyImage
        image={image.gatsbyImageData}
        alt={ensureProperImageryAlt(image.description)}
      />
    </InternalLink>
  );
}

type ImagerySearchFormProps = $ReadOnly<{
  allMediumGroups: $ReadOnlySet<MediumGroup>,
  selectedMediumGroups: $ReadOnlySet<MediumGroup>,
  onChangeSelectedMediumGroups: ($ReadOnlySet<MediumGroup>) => void,
  allCollections: $ReadOnlySet<string>,
  selectedCollections: $ReadOnlySet<string>,
  onChangeSelectedCollections: ($ReadOnlySet<string>) => void,
  numMatches: number,
  numTotal: number,
  onClearAllFilters: () => void,
}>;
function ImagerySearchForm(props: ImagerySearchFormProps): React.Node {
  const {
    allMediumGroups,
    selectedMediumGroups,
    onChangeSelectedMediumGroups,
    allCollections,
    selectedCollections,
    onChangeSelectedCollections,
    numMatches,
    numTotal,
    onClearAllFilters,
  } = props;
  const toggleGroups = [
    <ToggleGroup
      title='Collection'
      id={ELEMENT_IDS.IMAGERY_GALLERY_COLLECTION}
      allKeys={allCollections}
      selectedKeys={selectedCollections}
      onChange={onChangeSelectedCollections}
    />,
    <ToggleGroup
      title='Medium'
      id={ELEMENT_IDS.IMAGERY_GALLERY_MEDIUM_GROUP}
      allKeys={allMediumGroups}
      selectedKeys={selectedMediumGroups}
      prettyPrintLabel={prettyPrintMediumGroup}
      onChange={onChangeSelectedMediumGroups}
    />,
  ];
  const initiallyIsExpanded =
    selectedMediumGroups.size !== 0 || selectedCollections.size !== 0;
  return (
    <div css={styles.searchOptions}>
      <Accordion
        id={ELEMENT_IDS.IMAGERY_GALLERY_ADDITIONAL_FILTERS}
        title='Filter Results'
        initiallyIsExpanded={initiallyIsExpanded}
      >
        <ul css={styles.toggleGroupsList}>
          {toggleGroups.map((toggleGroup, idx) => (
            <li key={idx} css={styles.toggleGroupsListItem}>
              {toggleGroup}
            </li>
          ))}
        </ul>
      </Accordion>
      <div css={styles.belowFilters}>
        <div css={styles.matchCount}>
          {numMatches} matches (out of {numTotal})
        </div>
        <button css={styles.clearFiltersButton} onClick={onClearAllFilters}>
          Clear all filters
        </button>
      </div>
    </div>
  );
}

type ImagerySearchInnerProps = $ReadOnly<{
  allImageriesUnsorted: $ReadOnlyArray<ImageryWithImageData>,
}>;
/**
 * This is seaparated from ImagerySearch to avoid having the setState calls in
 * here cause a Page re-render.
 */
function ImagerySearchInner(props: ImagerySearchInnerProps): React.Node {
  const {allImageriesUnsorted} = props;
  const {
    allImageries,
    filteredImageries,
    updateQueryParams,
    clearQueryParams,
    queryParams,
    allMediumGroups,
    allCollections,
  } = useImagerySearch(allImageriesUnsorted);
  const numMatches = filteredImageries.length;
  return (
    <div css={styles.root}>
      <ImagerySearchForm
        allMediumGroups={allMediumGroups}
        selectedMediumGroups={queryParams.mediums}
        onChangeSelectedMediumGroups={(mediums) => updateQueryParams({mediums})}
        allCollections={allCollections}
        selectedCollections={queryParams.collections}
        onChangeSelectedCollections={(collections) =>
          updateQueryParams({collections})
        }
        numMatches={numMatches}
        numTotal={allImageries.length}
        onClearAllFilters={clearQueryParams}
      />
      {numMatches === 0 ? (
        <div css={styles.noResults}>No Results</div>
      ) : (
        <InfiniteScroll
          allItems={filteredImageries}
          // keep to a multiple of 3 just for fun
          itemsPerPage={3 * 10}
          getItemKey={(item) => item.id}
          ItemRenderer={ImagerySearchResult}
          rootStyle={styles.infiniteScrollRoot}
          listStyle={styles.resultsList}
          loadingIndicatorStyle={styles.loadingIndicator}
          loadMoreButtonStyle={styles.loadMoreButton}
        />
      )}
    </div>
  );
}

type Props = $ReadOnly<{}>;
export default function ImagerySearch(props: Props): React.Node {
  /**
   * IMPORTANT: Image width of 270 was chosen because
   *     270 ~ (800 / 3)
   * where
   *     800 = theme.maxMainContentWidth
   *     3 = number of results per row
   * This way, the image data that is fetched is just a bit larger than the size
   * needed for the actual size it would end up in layout.
   *
   * IMPORTANT: Idk why, but without the `breakpoints: [270]` bit, this will cause the browser to
   * always load a too-large image instead of the correctly sized one. I think its maybe because the
   * gatsby image utils all assume that your image is taking up roughly the full width of the page
   * (or at least the container?) whereas i am only using 1/3 of the container width.
   */
  const staticData: $ReadOnly<{
    allContentfulImagery: $ReadOnly<{
      nodes: $ReadOnlyArray<ImageryWithImageData>,
    }>,
  }> = useStaticQuery(graphql`
    query {
      allContentfulImagery {
        nodes {
          ...SearchableImageryFragment
          images {
            gatsbyImageData(
              width: 270
              breakpoints: [270]
              aspectRatio: 1
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);
  const {allContentfulImagery} = staticData;
  return (
    <Page
      title='Imagery'
      canonicalPath='/imagery/gallery/'
      ogImage={IMAGERY_OG_IMAGE}
      description='Image gallery of paintings, photographs, and other visual art by artist and musician Southerfields'
      pageRootStyle={styles.pageRoot}
    >
      <ImagerySearchInner allImageriesUnsorted={allContentfulImagery.nodes} />
    </Page>
  );
}
